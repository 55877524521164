<template>
  <div>
    
    <div id = "map">

       <v-card class="card-style" >
      <div
         class="justify-center align-center primary white--text text-center pa-3 body-1 card-header"
         
      >
        Information
      </div>

      <div class="text-center pa-2 body-2" >Station Status</div>

      <template v-for="(item, index) in station_status">
        <v-row class="px-5" :key="'B' + index">
          <v-col cols="6" class="px-4 py-3 text-center">
             <v-img
              height="30px"
              :src="item.img"
              contain
            ></v-img>
          </v-col>

          <v-col cols="6" class="'px-2 py-3 caption" >
            {{ item.text }}
          </v-col>
        </v-row>
      </template>
    </v-card>

        

    </div>
    

  </div>
</template>

<script>
// -- import something here --

import "ol/ol.css";

import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as GroupLayer,
  Image as ImageLayer
} from "ol/layer";
import { OSM, Vector as VectorSource, ImageArcGISRest} from "ol/source";
import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import Overlay from "ol/Overlay";

// Axios for fetching data
import axios from "axios";

export default {

  data: () => ({

    mapIcon: {
            green: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#00ff08",
                    }),
                    stroke: new Stroke({
                        color: "#ffffff ",
                        width: 3,
                    }),
                    radius: 10,
                    }),
            }),
            red: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#fb4343",
                    }),
                    stroke: new Stroke({
                        color: "#fb4343",
                        width: 1.25,
                    }),
                    radius: 8,
                    }),
            }),
            orange: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#fc8414",
                    }),
                    stroke: new Stroke({
                        color: "#fc8414",
                        width: 1.25,
                    }),
                    radius: 8,
                    }),
            }),
            grey: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#7c8a94",
                    }),
                    stroke: new Stroke({
                        color: "#7c8a94",
                        width: 1.25,
                    }),
                    radius: 8,
                    })
            }),
            cyan: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#078a88",
                    }),
                    stroke: new Stroke({
                        color: "#078a88",
                        width: 1.25,
                    }),
                    radius: 8,
                    })
            })
        },

    
    // Map Declaration

    map: null,
    view: null,
    extent:  [ 102.74131288335045, 1.4281723365439034, 103.94465766511522, 2.4005721601922105 ],
    baseMapLayer: {
      topographicMap: new TileLayer({
        title: "Topographic Map",
        source: new OSM({
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
       boundaryLayer: new ImageLayer({
        title: "Topographic Map",
        source: new ImageArcGISRest({
          url:
            "https://eqmp.scienotech.com.my/arcgis/rest/services/DEMARCATION/STATE/MapServer",
        }),
        visible: false,
        type: "layer",
      }),
      imageryMap: new TileLayer({
        title: "Imagery Map",
        source: new OSM({
          // "url" : 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          // "url": 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: true,
        type: "base",
      }),
      streetMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      googleStreetMap: new TileLayer({
        title: "Google Street",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      googleSateliteMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
    },
    pointStyle: {
      default: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#3381ff",
          }),
          stroke: new Stroke({
            color: "#0242ab",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
      selected: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#00ffff",
          }),
          stroke: new Stroke({
            color: "#003300",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
      
    },
    station_status: [
      {
        text: "Active",
        img: require("@/assets/green_circle.svg"),
      },
      {
        text: "Inactive",
        img: require("@/assets/grey_circle.svg"),
      },
      {
        text: "Transmission Delay",
        img: require("@/assets/orange_circle.svg"),
      },
    ],
  }),

  mounted() {
    this.initiateMap();
    this.getParamInfo();
    this.transition();
  },

  methods: {

    transition(){
      setTimeout(()=> {
         this.$router.push({ path: '/PublicDisplay/WQMS01' }).catch(()=>{});
      }
      ,5000);
    },

    initiateMap() {
      var source = new VectorSource();
      var vector = new VectorLayer({
        source: source,
      });

      var baseMap = new GroupLayer({
        layers: [
          this.baseMapLayer.topographicMap,
          this.baseMapLayer.imageryMap,
          this.baseMapLayer.streetMap,
          this.baseMapLayer.googleStreetMap,
          this.baseMapLayer.googleSateliteMap,
        ],
      });

      this.map = new Map({
        controls: defaultControls({
          zoom: false,
        }),
        target: "map",
        layers: [baseMap],
        view: new View({
          projection: "EPSG:4326",
          zoom: 1,
        }),
      });

      this.view = this.map.getView();
      this.view.fit(this.extent);

      this.map.on('moveend', this.onMoveEnd)

      this.onClickMap();

    },

    onClickMap() {
        let that = this;

        this.map.on("click", function(evt) {
            console.log("hehe");
        });
    },

     onMoveEnd(evt) {

              console.log(evt.pixel);
              // console.log(this.view);

                const map = evt.map;
                console.log("extent2", map.getView().calculateExtent())
                // console.log(fromLonLat(evt.coordinate, 'EPSG:3857', 'EPSG:4326'));
    },

    getParamInfo() {
      axios
        .get(this.globalUrl + "/bakaj/paramspublic", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.paramDetails = response.data;

          this.getStationInfo();
          
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStationInfo() {
      axios
        .get(this.globalUrl + "/bakaj/readingpublic", {
        // .get("https://run.mocky.io/v3/98873f4d-ebf7-44ff-9565-4fa2674af35c", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.stationDetails = response.data;

          this.addMapMarker();

        //   this.getStationInfoList();

        })
        .catch((error) => {
          console.log(error);
        });
    },

    addMapMarker() {
    //   this.addborderLayer();
      
      for (let i = 0; i < this.stationDetails.length; i++) {
        var marker = new Feature({
          geometry: new Point([
            this.stationDetails[i].LONGITUDE,
            this.stationDetails[i].LATITUDE,
          ]),
          description: this.stationDetails[i],
        });
        var vectorSource = new VectorSource({
          features: [marker],
        });

     

        var markerVectorLayer = new VectorLayer({
          name: "Automated",
          source: vectorSource,
          style:    this.stationDetails[i].GREEN == "TRUE"
                    ? this.mapIcon.green
                    : this.stationDetails[i].GREY == "TRUE" 
                    ? this.mapIcon.grey
                    : this.stationDetails[i].YELLOW == "TRUE"
                    ? this.mapIcon.green
                    : this.mapIcon.green,
        });

        this.map.addLayer(markerVectorLayer);
      }
      // this.addMapOverlayReading();

      this.addMapOverlay();
    },

     addMapOverlay() {
      for (let i = 0; i < this.stationDetails.length; i++) {

        let labelStationID = "label_" + this.stationDetails[i].STATION_ID_A;

        let divLabel = document.createElement("DIV");

        divLabel.setAttribute("id", labelStationID);
        divLabel.setAttribute("ref", labelStationID);

        divLabel.setAttribute("style", "background-color:#5A5A5A;color: white !important;z-index: 100; letter-spacing: 1px; padding: 4px;");

        let positioning 

         if (this.stationDetails[i].STATION_ID_A == 'WQMS06' || this.stationDetails[i].STATION_ID_A == 'WQMS05' || this.stationDetails[i].STATION_ID_A == 'WQMS01' )
            positioning = [-100, -15]
         else if (this.stationDetails[i].STATION_ID_A == 'WQMS03' || this.stationDetails[i].STATION_ID_A == 'WQMS04')
            positioning = [100, -13]
         else if (this.stationDetails[i].STATION_ID_A == 'WQMS02' )
            positioning = [123, -13]
         else   
            positioning = [0, -55]

          

        divLabel.innerHTML = this.stationDetails[i].STATION_ID_A + ", " + this.stationDetails[i].LOCATION;

        document.getElementById("map").appendChild(divLabel);


        let overlayLabel = new Overlay({
          element: document.getElementById(labelStationID),
          id: "label__" + this.stationDetails[i].STATION_ID_A,
          positioning: "top-center",
          offset: positioning
        });

        overlayLabel.setPosition([
          this.stationDetails[i].LONGITUDE,
          this.stationDetails[i].LATITUDE,
        ]);

        this.map.addOverlay(overlayLabel);
      }
    },
    
  },

};
</script>

<style lang="scss" scoped>
@import '~scss/main';


#map {
  position: fixed;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
//   background: black;
}

.card-style {
  position: absolute;
  z-index: 5;
  top: 250px;
  left: 30px;
  height: 280px;
  width: 250px;
}




</style>


